var OpenStreetMap = {
    map: {},
    markers: [],
    zoom: 10,

    initMap: function(){
        this.map = new OpenLayers.Map("openStreetMap");
        this.map.addLayer(new OpenLayers.Layer.OSM());

    },

    setMapCenter: function(){
        // Set map center
        this.map.setCenter(this.markers[0].markers[0].lonlat, this.zoom);
    },

    fixSplit: function (latlng) {
        return latlng.split('/');
    },

    setLongLat: function (latlng) {
        return new OpenLayers.LonLat(parseFloat(latlng[1]), parseFloat(latlng[0]))
            .transform(
                new OpenLayers.Projection("EPSG:4326"), // transform from WGS 1984
                this.map.getProjectionObject() // to Spherical Mercator Projection
            );
    },

    createMarker: function (latlng, end) {
        var latLngTemp = this.fixSplit(latlng);

        var lonLat = this.setLongLat(latLngTemp);

        var marker = new OpenLayers.Layer.Markers("Markers");
        this.map.addLayer(marker);
        marker.addMarker(new OpenLayers.Marker(lonLat));
        this.markers.push(marker);
        if(end){
            this.setMapCenter();
        }
    },
};

OpenStreetMap.initMap();

// Select all and create markers
var datas = document.querySelectorAll('.stores--data-latlng');
for (var key in datas) {
    if (typeof datas[key] === 'object') {
        var end = (datas.length == (parseInt(key)+1)) ? true : false;
        OpenStreetMap.createMarker(datas[key].getAttribute('data-latlng'), end);
    }
}